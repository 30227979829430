const DocMenuConfig = [
  {
    pages: [
      // {
      //   heading: "dashboard",
      //   route: "/dashboard",
      //   fontIcon: "bi-speedometer",
      // },
      {
        permission: "affiliates.view",
        heading: "Affiliates",
        route: "/affiliates",
        fontIcon: "bi-asterisk",
      },
      {
        permission: "offers.view",
        heading: "Offers",
        route: "/offers",
        fontIcon: "bi-tags",
      },
      {
        heading: "Statistics",
        route: "/stats",
        fontIcon: "bi-pie-chart",
      },
      {
        heading: "Leads",
        route: "/leads",
        fontIcon: "bi-basket",
      },
      {
        permission: "sales.view",
        heading: "Sales",
        route: "/sales",
        fontIcon: "bi-cart",
      },
      {
        permission: "revision.view",
        heading: "Revision",
        route: "/revision",
        fontIcon: "bi-search",
      },
      {
        permission: "compensations.manage",
        heading: "Compensations",
        route: "/compensations",
        fontIcon: "bi-file-diff",
      },
      {
        permission: "payouts.view",
        heading: "Payouts",
        route: "/payouts",
        fontIcon: "bi-coin",
      },
      {
        heading: "Postback",
        route: "/postback",
        fontIcon: "bi-bell",
      },
      {
        permission: "requests.view",
        heading: "Requests",
        route: "/requests",
        fontIcon: "bi-file-lock2",
      },
      {
        heading: "Users",
        route: "/users",
        fontIcon: "bi-people",
        moderatorOnly: true,
      },
      {
        permission: "roles.view",
        heading: "Roles",
        route: "/roles",
        fontIcon: "bi-shield",
      },

    ],
  },
];

export default DocMenuConfig;
